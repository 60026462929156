import React, { useCallback } from 'react';
import { Input, Button, Form } from 'antd';
import QueueAnim from 'rc-queue-anim';
import SEO from 'components/SEO';
import { Link } from 'gatsby-plugin-react-i18next';
import { useTranslation, Trans } from 'react-i18next';
import startCase from 'lodash/startCase';
import { signUpLoadingSelector } from 'redux/Auth/selector';
import { useSelector, useDispatch } from 'react-redux';
import { signUp } from 'redux/Auth/slice';
import AuthLayout from 'components/Layout/AuthLayout';

function SignUpPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const loading = useSelector(signUpLoadingSelector);

  const handleSubmit = useCallback(
    (values) => {
      dispatch(signUp(values));
    },
    [dispatch]
  );

  const validatePasswordRegex = (_, value) => {
    if (!/^[\S]+.*[\S]+$/g.test(value)) {
      return Promise.reject(new Error(t('passwordRegex')));
    }
    return Promise.resolve();
  };

  return (
    <AuthLayout>
      <SEO title={t('signUp')} description={t('SEODescription')} />
      <QueueAnim
        className="auth-banner-content-wrapper"
        delay={300}
        ease="easeOutQuart"
      >
        <h1 key="1">{startCase(t('signUp'))}</h1>
        <Form key="2" onFinish={handleSubmit} className="auth-form" form={form}>
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: t('invalidEmail'),
              },
              {
                required: true,
                message: t('requiredEmail'),
              },
            ]}
          >
            <Input
              type="email"
              addonBefore={t('email')}
              placeholder="johndoe@example.com"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t('requiredPassword'),
              },
              {
                min: 8,
                message: t('minPassword'),
              },
              {
                max: 35,
                message: t('maxPassword'),
              },
              {
                validator: validatePasswordRegex,
              },
            ]}
          >
            <Input.Password
              addonBefore={t('password')}
              placeholder="********"
            />
          </Form.Item>
          <Form.Item
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: t('requiredConfirmPassword'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t('inconsistentPassword')));
                },
              }),
            ]}
            name="confirm"
          >
            <Input.Password
              addonBefore={t('confirmPassword')}
              placeholder="********"
            />
          </Form.Item>
          <Form.Item>
            <Trans i18nKey="signUpConfirm">
              By clicking Sign up, you agree to our <Link to="/">Terms</Link>
              and <Link to="/">Cookie Policy</Link>.
            </Trans>
            <Button
              type="primary"
              htmlType="submit"
              className="full-width"
              size="large"
              loading={loading}
            >
              {t('signUp')}
            </Button>
            {t('alreadyAccount')}
            <Link to="/auth/sign-in">{t('signIn')}</Link>
          </Form.Item>
        </Form>
      </QueueAnim>
    </AuthLayout>
  );
}

export default React.memo(SignUpPage);
